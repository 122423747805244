<template>
  <main class="overflow-hidden">
    <!-- Navigation -->
    <BlockNavigation
      v-if="route.name !== 'orders-id'"
      :user="user" />

    <!-- Page Slot -->
    <slot />
    <!-- Login Modal -->
    <ModalLogin v-if="showLoginModal" />
    <Intercom />
  </main>
</template>

<script setup lang="ts">
const user = useSupabaseUser()
const route = useRoute()
const showLoginModal = useState('showLoginModal', () => false)

useSeoMeta({
  title: 'PicUp | The Easiest Way To Move Your Things',
  description: 'Picup offer services from small moves, to business deliveries and waste removal. Get an instant quote today!',
  ogImage: '/social-preview.png',
  ogUrl: 'https://picup.com.au',
})
</script>
